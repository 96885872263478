var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1",attrs:{"tile":""}},[_c('v-card-text',[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'email'),"label":_vm.$t('hr.employee.contact.email'),"rules":"email|max:255","index":0,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"280px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.email),callback:function ($$v) {_vm.$set(_vm.employeePayload, "email", $$v)},expression:"employeePayload.email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'secondaryPhone'),"label":_vm.$t('hr.employee.contact.secondary_phone'),"rules":"phone","index":1,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-picker',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.secondaryPhone),callback:function ($$v) {_vm.$set(_vm.employeePayload, "secondaryPhone", $$v)},expression:"employeePayload.secondaryPhone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'fixedPhone'),"label":_vm.$t('hr.employee.contact.fixed_phone'),"rules":"phone","index":2,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-picker',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.fixedPhone),callback:function ($$v) {_vm.$set(_vm.employeePayload, "fixedPhone", $$v)},expression:"employeePayload.fixedPhone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'workPhone'),"label":_vm.$t('hr.employee.contact.work_phone'),"rules":"phone","index":3,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-picker',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.workPhone),callback:function ($$v) {_vm.$set(_vm.employeePayload, "workPhone", $$v)},expression:"employeePayload.workPhone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'address'),"label":_vm.$t('hr.employee.contact.address'),"rules":"max:512","index":4,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"600px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"512","error-messages":errors},model:{value:(_vm.employeePayload.address),callback:function ($$v) {_vm.$set(_vm.employeePayload, "address", $$v)},expression:"employeePayload.address"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'spousePhone'),"label":_vm.$t('hr.employee.contact.spouse_phone'),"rules":"phone","index":5,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-picker',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.spousePhone),callback:function ($$v) {_vm.$set(_vm.employeePayload, "spousePhone", $$v)},expression:"employeePayload.spousePhone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'extensionNumber'),"label":_vm.$t('hr.employee.contact.extension_number'),"index":6,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary"},model:{value:(_vm.employeePayload.extensionNumber),callback:function ($$v) {_vm.$set(_vm.employeePayload, "extensionNumber", $$v)},expression:"employeePayload.extensionNumber"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'emergencyContactName'),"label":_vm.$t('hr.employee.contact.emergency_contact_name'),"rules":"max:255","index":7,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.emergencyContactName),callback:function ($$v) {_vm.$set(_vm.employeePayload, "emergencyContactName", $$v)},expression:"employeePayload.emergencyContactName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'emergencyContactPhone'),"label":_vm.$t('hr.employee.contact.emergency_contact_phone'),"rules":"phone","index":8,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-picker',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.emergencyContactPhone),callback:function ($$v) {_vm.$set(_vm.employeePayload, "emergencyContactPhone", $$v)},expression:"employeePayload.emergencyContactPhone"}})]}}],null,true)})],1),_vm._l((_vm.employeePayload.objectAttributeValues),function(objectAttributeValue,idx){return [_c('v-col',{key:idx,staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":objectAttributeValue.value,"label":objectAttributeValue.name,"index":9 + idx,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(objectAttributeValue.value),callback:function ($$v) {_vm.$set(objectAttributeValue, "value", $$v)},expression:"objectAttributeValue.value"}})]}}],null,true)})],1)]})],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }